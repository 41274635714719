export interface OrthoCanvasProperties {
  strokeWidthFactor: number;
  strokeWidth: number;
  left: number;
  zoomLevel: number;
  rotate: number;
  zoom: number;
  contrast: number;
  brightness: number;
}

export interface CanvasState {
  canvas?: string;
  viewportTransform?: number[];
}

export enum POLYGON_TYPES {
  LINE = "line",
  RULER = "ruler",
  BBOX = "bbox",
  POLYGON = "polygon",
  ARCH = "arch",
}
