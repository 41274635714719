import {
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  IconDefinition,
  faArrowsUpDownLeftRight,
} from "@fortawesome/pro-light-svg-icons";

export enum EventConstants {
  SHOW_KEYS_MAP = "SHOW-KEYS-MAP",
  HIDE_KEYS_MAP = "HIDE-KEYS-MAP",
  FMX_IMAGE_SELECTED = "FMX-IMAGE-SELECTED",
  FMX_IMAGE_UNSELECTED = "FMX-IMAGE-UNSELECTED",
  FMX_IMAGE_PROCEDURE_SELECTED = "FMX-IMAGE-PROCEDURE-SELECTED",
  FMX_SIDEBAR_PROCEDURE_SELECTED = "FMX-SIDEBAR-PROCEDURE-SELECTED",
  ADDITIONAL_IMAGE_SELECTED = "ADDITIONAL-IMAGE-SELECTED",
  FMX_HIGHLIGHT_SHOW = "highlight:show",
  FMX_HIGHLIGHT_HIDE = "highlight:hide",
  CANVAS_SHOW_COMMENTS = "CANVAS-SHOW-COMMENTS",
  CANVAS_HIDE_COMMENTS = "CANVAS-HIDE-COMMENTS",
  CANVAS_SHOW_NARRATIVES = "CANVAS-SHOW-NARRATIVES",
  CANVAS_TOGGLE_ANNOTATION_CATEGORY = "CANVAS-TOGGLE-ANNOTATION-CATEGORY",
  CANVAS_TOGGLE_ANNOTATION_POLYGON = "CANVAS-TOGGLE-ANNOTATION-POLYGON",
  CANVAS_ZOOM_INCREASE = "CANVAS_ZOOM-INCREASE",
  CANVAS_ZOOM_DECREASE = "CANVAS_ZOOM-DECREASE",
  CANVAS_CONTRAST_INCREASE = "CANVAS_CONTRAST-INCREASE",
  CANVAS_CONTRAST_DECREASE = "CANVAS_CONTRAST-DECREASE",
  CANVAS_BRIGHTNESS_INCREASE = "CANVAS_BRIGHTNESS-INCREASE",
  CANVAS_BRIGHTNESS_DECREASE = "CANVAS_BRIGHTNESS-DECREASE",
  CANVAS_IMAGE_RESET = "CANVAS-IMAGE_RESET",
  CANVAS_IMAGE_ROTATE = "CANVAS-IMAGE_ROTATE",
  CANVAS_IMAGE_FLIP = "CANVAS-IMAGE_FLIP",
  CANVAS_IMAGE_SELECTED = "CANVAS-IMAGE-SELECTED",
  CANVAS_PROCEDURE_SELECTED = "CANVAS-PROCEDURE-SELECTED",
  CANVAS_PROCEDURE_SELECTED_FROM_PANEL = "CANVAS-PROCEDURE-SELECTED-FROM-PANEL",
  CANVAS_NEXT_CLAIM_ALERT_STATUS = "CANVAS-NEXT-CLAIM-ALERT-STATUS",
  CANVAS_GALLERY_CURSOR_RESET = "CANVAS-GALLERY-CURSOR-RESET",
  CANVAS_COMMENTS_RESET = "CANVAS-COMMENTS-RESET",
  TRACK_SET_PROFILE = "TRACK-SET-PROFILE",
  TRACK_UNSET_PROFILE = "TRACK-UNSET-PROFILE",
  TRACK_ALL_SELECT_CLAIM = "TRACK-ALL-SELECT-CLAIM",
  TRACK_QUEUE_SELECT_CLAIM = "TRACK-QUEUE-SELECT-CLAIM",
  TRACK_CANVAS_CLAIM_INFORMATION = "TRACK-CANVAS-CLAIM-INFORMATION",
  TRACK_CANVAS_OPEN_PROCEDURE_DROPDOWN = "TRACK-CANVAS-OPEN-PROCEDURE-DROPDOWN",
  TRACK_CANVAS_ACTIVE_PROCEDURE = "TRACK-CANVAS-ACTIVE-PROCEDURE",
  TRACK_CANVAS_SELECT_PROCEDURE_IMAGE = "TRACK-CANVAS-SELECT-PROCEDURE-IMAGE",
  TRACK_CANVAS_SELECT_ALL_ATTACHMENTS_IMAGE = "TRACK-CANVAS-SELECT-ALL-ATTACHMENTS-IMAGE",
  TRACK_CANVAS_SELECT_ONE_ANNOTATION = "TRACK-CANVAS-SELECT-ONE-ANNOTATION",
  TRACK_CANVAS_SELECT_ALL_ANNOTATIONS = "TRACK-CANVAS-SELECT-ALL-ANNOTATIONS",
  TRACK_CANVAS_SELECT_PROCEDURE_TABLE = "TRACK-CANVAS-SELECT-SERVICE-LINE",
  TRACK_CANVAS_START_COMMENT = "TRACK-CANVAS-START-COMMENT",
  TRACK_CANVAS_VIEW_NARRATIVE = "TRACK-CANVAS-VIEW-NARRATIVE",
  TRACK_CANVAS_SHOW_KEYBOARD = "TRACK-CANVAS-TOGGLE-KEYS",
  TRACK_CANVAS_IMAGE_TOOLS = "TRACK-CANVAS-IMAGE-TOOLS",
  TRACK_CANVAS_NEXT_CLAIM = "TRACK-CANVAS-NEXT-CLAIM",
  TRACK_CANVAS_PREVIOUS_CLAIM = "TRACK-CANVAS-PREVIOUS-CLAIM",
  TRACK_CANVAS_EXIT_CLAIM = "TRACK-CANVAS-EXIT-CLAIM",
  TRACK_CANVAS_REVIEW = "TRACK_CANVAS_REVIEW",
}

export const CanvasKeys: Array<{
  name: string | Array<string | IconDefinition>;
  detail: string;
}> = [
  {
    name: "K",
    detail: "Toggle Keyboard Shortcuts",
  },
  {
    name: "N",
    detail: "View Narrative",
  },
  {
    name: "M",
    detail: "View Comments",
  },
  {
    name: "R",
    detail: "Reset Image Adjustments",
  },
  {
    name: "SPACE",
    detail: "Toggle Annotations",
  },
  {
    name: "P",
    detail: "View Periodontal Charting",
  },
  {
    name: ["SHIFT", faArrowsUpDownLeftRight],
    detail: "Move Image After Zooming",
  },
  {
    name: ["I", faArrowUp],
    detail: "Select the image above",
  },
  {
    name: ["I", faArrowDown],
    detail: "Select the image below",
  },
  {
    name: ["I", faArrowLeft],
    detail: "Select the image to the left",
  },
  {
    name: ["I", faArrowRight],
    detail: "Select the image to the right",
  },
  {
    name: ["Z", faArrowUp],
    detail: "Zoom In",
  },
  {
    name: ["Z", faArrowDown],
    detail: "Zoom Out",
  },
  {
    name: ["C", faArrowUp],
    detail: "Increase Contrast",
  },
  {
    name: ["C", faArrowDown],
    detail: "Decrease Contrast",
  },
  {
    name: ["B", faArrowUp],
    detail: "Increase Brightness",
  },
  {
    name: ["B", faArrowDown],
    detail: "Decrease Brightness",
  },
  {
    name: ["SHIFT", "CTRL", faArrowRight],
    detail: "Rotation Right",
  },
  {
    name: ["SHIFT", "CTRL", faArrowLeft],
    detail: "Rotation Left",
  },
  {
    name: ["SHIFT", "CTRL", "H"],
    detail: "Flip Horizontal",
  },
  {
    name: ["SHIFT", "CTRL", "V"],
    detail: "Flip Vertical",
  },
  {
    name: "ESC",
    detail: "Back",
  },
  {
    name: "TAB",
    detail: "Continue To Next Element",
  },
  {
    name: "ENTER",
    detail: "Submit Input",
  },
  {
    name: ["SHIFT", "L"],
    detail: "Log Out",
  },
];

export const FmxKeys: Array<{
  name: string | Array<string | IconDefinition>;
  detail: string;
}> = [
  {
    name: "K",
    detail: "Toggle Keyboard Shortcuts",
  },
  {
    name: "TAB",
    detail: "Continue To Next Element",
  },
  {
    name: "ENTER",
    detail: "Submit Input",
  },
  {
    name: "1-9",
    detail: "Jump To Procedure Line",
  },
  {
    name: ["SHIFT", "L"],
    detail: "Log Out",
  },
];

export enum KeyboardKeys {
  ALT = "alt",
  ARROW_DOWN = "ArrowDown",
  ARROW_LEFT = "ArrowLeft",
  ARROW_RIGHT = "ArrowRight",
  ARROW_UP = "ArrowUp",
  CHAR_B = "b",
  CHAR_C = "c",
  CHAR_H = "h",
  CHAR_I = "i",
  CHAR_K = "k",
  CHAR_L = "l",
  CHAR_M = "m",
  CHAR_N = "n",
  CHAR_P = "p",
  CHAR_R = "r",
  CHAR_V = "v",
  CHAR_Z = "z",
  CTRL = "ctrl",
  DOWN = "down",
  ENTER = "Enter",
  ESC = "Escape",
  LEFT = "left",
  NUM_NINE = "9",
  NUM_ONE = "1",
  RIGHT = "right",
  SHIFT = "shift",
  SPACE_BAR = "Space",
  TAB = "Tab",
  UP = "up",
}
